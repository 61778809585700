<template>
  <div :class="['list-item', { active }]" @click="$emit('select')">
    <span class="label">
      {{ team.teamName }}
      <a-tag v-if="team.isRoot" color="orange">此空间</a-tag>
    </span>
    <span v-if="!team.isRoot" class="actions">
      <i
        class="ndl-icon-edit-line"
        title="修改团队名称"
        @click.stop="$emit('edit')"
      ></i>
      <i
        class="danger ndl-icon-close-circle-fill"
        title="删除团队"
        @click.stop="$emit('remove')"
      ></i>
    </span>
    <span class="desc">
      {{ team.isRoot ? "共" : "" }} {{ team.members.length }} 人
    </span>
  </div>
</template>

<script>
export default {
  props: {
    /**
     * @type {import("@/views/settings/models/team").default}
     */
    team: Object,
    active: Boolean
  },
  data() {
    return {
      editing: false
    };
  }
};
</script>

<style lang="less" scoped>
@import (reference) "~@/assets/app.less";

@height: 48px;
.list-item {
  height: @height;
  display: flex;
  padding: 0 12px 0 22px;
  cursor: pointer;
  display: flex;
  align-items: center;
  transition: all 0.3s;
  &:hover {
    background: #f5f5f5;
  }
  &.active {
    background: @blue-1;
  }
  .label {
    flex: 1 1 0;
    width: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  &.active .label {
    color: @blue-6;
  }
  .ant-tag {
    border: none;
    border-radius: 2px;
    margin-left: 4px;
    cursor: inherit;
  }
  .desc,
  .actions {
    flex-shrink: 0;
    color: @text-color-secondary;
  }
  .actions {
    display: none;
    font-size: 16px;
  }
  &:hover .actions {
    display: block;
  }
  &:hover .actions + .desc {
    display: none;
  }
  .actions i {
    margin-left: 12px;
    &:hover {
      color: @blue-6;
    }
    &.danger:hover {
      color: @red-6;
    }
  }
}
</style>
