<template>
  <div class="ndl-padding-lg ndl-width-limit">
    <header class="ndl-flex ndl-flex--middle ndl-margin-bottom-lg">
      <div class="ndl-text-heading ndl-flex-item--grow">空间管理员</div>
      <a-button ghost type="primary" @click="onAddSubAdmin()">
        添加子管理员
      </a-button>
    </header>
    <ManagerList
      style="margin-top: 46px"
      :members="managers"
      @change-mainadmin="onChangeMainAdmin"
      @remove-subadmin="onRemoveSubAdmin"
    />
  </div>
</template>

<script>
import * as API from "@/api/space";
import ManagerList from "./components/manager-list";
import ManagerCard from "./components/manager-card";
import org from "./models/organization";
import pickMembers from "./components/unit-picker";

export default {
  components: { ManagerList },
  computed: {
    managers() {
      return org.members.filter(item => item.isMainAdmin || item.isSubAdmin);
    }
  },
  methods: {
    async onAddSubAdmin() {
      let members = await pickMembers();
      await API.addSubAdmin({ memberId: members.map(item => item.memberId) });
      // pickMembers 返回的是 org.members 克隆出来的对象，因此要找出原始对象
      members = members.map(item =>
        org.members.find(member => item.memberId === member.memberId)
      );
      for (const item of members) {
        item.isSubAdmin = true;
      }
    },
    async onChangeMainAdmin() {
      const [member] = await pickMembers({ multiple: false });
      this.$confirm({
        title: "是否确认将主管理员转让给",
        // eslint-disable-next-line no-unused-vars
        content: h => <ManagerCard member={member} />,
        okType: "danger",
        okText: "确认转让",
        onOk: () => {
          const params = new URLSearchParams();
          params.append("memberId", member.memberId);
          params.append("spaceId", member.spaceId);
          return API.changeMainAdmin(params).then(() => org.update());
        }
      });
    },
    async onRemoveSubAdmin(member) {
      this.$confirm({
        title: "是否确定要撤销子管理员？",
        content: `正在撤销子管理员「${member.memberName}」`,
        width: 380,
        okType: "danger",
        okText: "确认撤销",
        onOk: () =>
          API.deleteSubAdmin(member.memberId).then(() => {
            member.isSubAdmin = false;
          })
      });
    }
  }
};
</script>
