<template>
  <a-result
    status="403"
    :title="`空间「${spaceInfo.spaceName}」已放入回收站`"
    :sub-title="`将于${spaceInfo.preDelTime || '2050-01-01 00:00:00'}彻底删除`"
  >
    <template #extra>
      <a-button type="primary" icon="rollback" @click="revertSpace()">
        恢复空间
      </a-button>
      <a-button type="danger" icon="delete" @click="dropSpace()">
        立即删除
      </a-button>
    </template>
  </a-result>
</template>

<script>
import { dropSpace, revertSpace } from "@/api/space";
import store from "@/store";

export default {
  computed: {
    spaceInfo() {
      return store.spaceInfo || {};
    }
  },
  beforeRouteEnter(to, from, next) {
    const spaceInfo = store.spaceInfo || {};
    // 不是主管理员或者空间未标记删除就不要进来凑热闹了
    if (!spaceInfo.isAdmin) return next("/");
    if (!spaceInfo.isPreDelete) return next("/");
    next();
  },
  methods: {
    async revertSpace() {
      const spaceId = this.spaceInfo.spaceId;
      await revertSpace({ spaceId });
      await store.updateUserInfo();
      this.$router.push("/");
    },
    dropSpace() {
      this.$confirm({
        title: "确认要彻底删除空间？",
        content: "空间删除后将不可恢复，包含所有表格、附件都会被彻底删除",
        okText: "删除",
        okType: "danger",
        cancelText: "取消",
        onOk: async () => {
          await dropSpace(this.spaceInfo.spaceId);
          // 回到另一个空间，由后端指定 spaceId
          await store.updateUserInfo();
          this.$router.push("/");
        }
      });
    }
  }
};
</script>
