<template>
  <div class="ndl-padding-lg ndl-width-limit ndl-antd">
    <div class="ndl-text-heading">
      <a @click="$emit('cancel')" class="ndl-margin-right-sm">
        <i class="ndl-icon-arrow-left"></i>
      </a>
      <span>{{ space ? "配置空间" : "新建空间" }}</span>
    </div>
    <a-form :label-col="{ span: 3 }" :wrapper-col="{ span: 10 }" :colon="false">
      <!-- 用于避免自动填充的隐藏表单 -->
      <div style="height: 0; overflow: hidden">
        <input tabindex="-1" type="text" />
        <input tabindex="-1" type="password" />
      </div>
      <!-- 空间基本信息 -->
      <div class="ndl-text-leading" style="margin-top: 46px">
        <span>空间基本信息 </span>
        <a-tooltip title="配置「连接」、「视图」相关功能的计算引擎地址、端口">
          <i class="ndl-icon-help-circle ndl-color-gray"></i>
        </a-tooltip>
      </div>
      <a-form-item label="空间名称" required>
        <a-input v-model="model.spaceName" placeholder="请输入空间名称" />
      </a-form-item>
      <a-form-item label="有效期至" help="永久有效的空间可不设置过期时间">
        <a-date-picker
          :value="model.spaceExpireTime"
          placeholder="选择日期"
          style="width: 100%"
          @change="(a, b) => (model.spaceExpireTime = b)"
        />
      </a-form-item>
      <a-form-item label="连接服务地址">
        <a-input-group>
          <a-row :gutter="8">
            <a-col :span="18">
              <a-input v-model="model.airbyteIp" placeholder="域名 / IP 地址" />
            </a-col>
            <a-col :span="6">
              <a-input-number
                v-model="model.airbytePort"
                style="width: 100%"
                placeholder="默认 80"
              />
            </a-col>
          </a-row>
        </a-input-group>
      </a-form-item>
      <a-form-item label="视图服务地址">
        <a-input-group>
          <a-row :gutter="8">
            <a-col :span="18">
              <a-input v-model="model.msqueryIp" placeholder="域名 / IP 地址" />
            </a-col>
            <a-col :span="6">
              <a-input-number
                v-model="model.msqueryPort"
                style="width: 100%"
                placeholder="默认 80"
              />
            </a-col>
          </a-row>
        </a-input-group>
      </a-form-item>
      <!-- 设置主管理员 -->
      <div class="ndl-text-leading ndl-margin-top-lg">设置主管理员</div>
      <a-form-item label="主管理员邮箱" required>
        <a-input v-model="model.spaceAdmin.mail" placeholder="邮箱">
          <i slot="prefix" class="ndl-icon-mail"></i>
        </a-input>
      </a-form-item>
      <a-form-item label="主管理员密码" required>
        <a-input-password
          v-model="model.spaceAdmin.password"
          placeholder="密码"
          type="password"
        >
          <i slot="prefix" class="ndl-icon-lock"></i>
        </a-input-password>
      </a-form-item>
      <template v-if="!(space && space.isSuperSpace)">
        <!-- 空间功能权限 -->
        <div class="ndl-text-leading ndl-margin-top-lg">空间功能权限</div>
        <a-form-item>
          <SelectModules v-model="model.enableModules" />
        </a-form-item>
        <!-- 预装连接器 -->
        <div class="ndl-text-leading ndl-margin-top-lg">默认数据源连接器</div>
        <DefinitionList v-model="model.definitions" category="source" />
        <div class="ndl-text-leading ndl-margin-top-lg">默认目标库连接器</div>
        <DefinitionList v-model="model.definitions" category="destination" />

        <div class="ndl-text-leading ndl-margin-top-lg">
          添加自定义目标库连接器(可选)
        </div>
        <a-select
          v-model="destinationTarget"
          style="width: 300px"
          placeholder="清选择"
          @select="onSelectSchema"
        >
          <a-select-option value="">请选择</a-select-option>
          <a-select-option
            v-for="(item, index) in destinationList"
            :key="index"
            :value="item.id"
          >
            {{ item.name }}
          </a-select-option>
        </a-select>
        <ConnectorEditor
          class="connector-editor"
          v-model="connector"
          ref="editor"
          v-if="definitionId"
          :definition-id="definitionId"
        />
      </template>
      <a-form-item class="ndl-margin-top-lg ndl-margin-bottom-xl">
        <a-button type="primary" :loading="loading" @click="onConfirm()">
          {{ space ? "更新空间配置" : "新建空间" }}
        </a-button>
        <a-button class="ndl-margin-left" @click="$emit('cancel')">
          取消
        </a-button>
      </a-form-item>
    </a-form>
  </div>
</template>

<script>
import * as API from "@/api/space";
import DefinitionList from "./DefinitionList";
import SelectModules from "./SelectModules";
import { definitionList, Types } from "@/views/connections/models/definitions";
import ConnectorEditor from "@/views/connections/components/connector-editor/src/Editor.vue";

export default {
  //

  components: { DefinitionList, SelectModules, ConnectorEditor },
  props: {
    space: Object
  },
  data() {
    const space = this.space || {};
    return {
      loading: false,
      destinationTarget: "",
      definitionId: "",
      connector: {},
      model: Object.assign(
        {
          spaceName: "",
          spaceExpireTime: null,
          airbyteIp: "",
          airbytePort: 80,
          msqueryIp: "",
          msqueryPort: 80,
          definitions: [],
          enableModules: [
            "connections",
            "datamodel",
            "datamodel.api",
            "datamodel.schedule"
          ],
          spaceAdmin: Object.assign(
            {
              mail: "",
              password: ""
            },
            space.spaceAdmin
          )
        },
        this.space
      )
    };
  },
  methods: {
    onSelectSchema(value) {
      this.definitionId = value;
    },
    async onConfirm() {
      if (this.destinationTarget) {
        if (!this.$refs.editor.validate()) {
          return;
        }
      }
      const model = this.model;
      const spaceAdmin = model.spaceAdmin;
      const isValid = model.spaceName && spaceAdmin.mail && spaceAdmin.password;
      if (!isValid) {
        this.$message.warning("请检查必填项");
        this.$el.scrollIntoView({
          behavior: "smooth"
        });
        return;
      }
      if (Object.prototype.hasOwnProperty.call(model, "mainDestination")) {
        delete model.mainDestination;
      }
      if (this.destinationTarget) {
        let mainDestination = {
          ...this.connector,
          category: "destination",
          definitionId: this.definitionId
        };
        model["mainDestination"] = mainDestination;
      }
      try {
        this.loading = true;
        if (this.space) {
          await API.updateSpace(model);
        } else {
          await API.createSpace(model);
        }
        this.loading = false;
      } catch (err) {
        this.loading = false;
        return;
      }
      this.$success({
        title: this.space ? "已更新空间配置" : "空间新建成功",
        okText: "好的",
        // eslint-disable-next-line no-unused-vars
        content(h) {
          return (
            <a-result
              status="success"
              title={model.spaceName}
              style="padding: 0"
            >
              <div slot="subTitle" class="ndl-margin-top">
                <p>主管理员邮箱：{spaceAdmin.mail}</p>
                <p>密码：{spaceAdmin.password}</p>
              </div>
            </a-result>
          );
        },
        onOk: () => {
          this.$emit("confirm");
          this.$emit("cancel");
        }
      });
    }
  },
  computed: {
    destinationList() {
      return definitionList.definitions.filter(item => {
        return item.category == "destination";
      });
    },
    definition() {
      return definitionList.getDefinition(this.definitionId) || {};
    },
    category() {
      return this.definition.category || Types.SOURCE;
    },
    definitionIcon() {
      return definitionList.getIcon(this.definitionId);
    }
  },
  created() {
    if (
      this.space &&
      Object.prototype.hasOwnProperty.call(this.space, "mainDestination")
    ) {
      this.connector = {
        connectionConfiguration: this.space.mainDestination
          .connectionConfiguration,
        name: this.space.mainDestination.name
      };
      this.definitionId = this.space.mainDestination.definitionId;
      this.destinationTarget = this.space.mainDestination.definitionId;
    }
  }
};
</script>
<style>
.connector-editor {
  margin: 0 !important;
  margin-top: 30px !important;
  padding: 0 !important;
}
</style>
