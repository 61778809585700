<template>
  <a-modal title="批量导入成员" width="680px" :footer="null">
    <!-- 导入结果 -->
    <a-result
      v-if="uploadResult"
      :status="uploadResult.successCount ? 'success' : 'error'"
      title="导入完成"
    >
      <template #subTitle>
        <p>总条数：{{ uploadResult.rowCount }}条</p>
        <p>导入成功：{{ uploadResult.successCount }}条</p>
        <p>导入失败：{{ uploadResult.errorCount }}条</p>
      </template>
      <template #extra>
        <a-button @click="uploadResult = null">继续导入</a-button>
        <a-button type="primary" @click="$resolve()">
          完成导入
        </a-button>
      </template>
    </a-result>
    <!-- 文件拖放区域 -->
    <a-upload-dragger
      v-else
      accept=".xlsx"
      class="upload-dragger"
      :custom-request="uploadFile"
      :show-upload-list="false"
      :disabled="loading"
    >
      <p style="color: #1890ff; font-size: 48px; margin-bottom: 20px">
        <i class="ndl-icon-upload-cloud"></i>
      </p>
      <p class="ant-upload-text">点击或拖拽文件到此次开始导入</p>
      <p class="ndl-text-desc-small">
        <span>请根据模板导入，仅支持 .xlsx 文件。</span>
        <a href="files/成员导入模板.xlsx" @click.stop>下载导入模板</a>
      </p>
      <div class="ndl-margin-top-xl">
        <a-button @click.native.stop="$resolve()">
          取消
        </a-button>
        <a-button type="primary" class="ndl-margin-left-sm" :loading="loading">
          {{ loading ? "正在上传" : "选择文件..." }}
        </a-button>
      </div>
    </a-upload-dragger>
  </a-modal>
</template>

<script>
import { uploadMemberFile } from "@/api/space";

export default {
  props: {
    teamId: String,
    $resolve: Function
  },
  data() {
    return { loading: false, uploadResult: null };
  },
  methods: {
    async uploadFile({ file }) {
      try {
        this.loading = true;
        const formData = new FormData();
        formData.append("file", file);
        if (this.teamId) {
          formData.append("teamId", this.teamId);
        }
        const { data } = await uploadMemberFile(formData);
        this.uploadResult = data;
        return data;
      } finally {
        this.loading = false;
      }
    }
  }
};
</script>

<style lang="less" scoped>
.upload-dragger {
  display: block;
  height: 420px;
  /deep/ .ant-upload-drag {
    background: none;
    border: none;
  }
}
</style>
