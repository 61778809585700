<template>
  <div class="card">
    <Avatar
      circle
      size="36px"
      :border="false"
      :color="member.memberId | colorOf"
      :src="member.avatar"
      :text="member.memberName"
    />
    <span class="label">{{ member.memberName }}</span>
  </div>
</template>

<script>
import Avatar, { colorOf } from "@/components/avatar";

export default {
  components: { Avatar },
  props: {
    member: Object
  },
  filters: { colorOf }
};
</script>

<style lang="less" scoped>
.card {
  display: flex;
  align-items: center;
  background: #f5f5f5;
  border-radius: 8px;
  padding: 16px 22px;
  margin: 42px 24px 12px;
  justify-content: center;
  .label {
    font-size: 16px;
    font-weight: 500;
    margin-left: 12px;
  }
}
</style>
