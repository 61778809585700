<template>
  <div>
    <!-- 成员数据 -->
    <div class="ndl-text-leading" style="margin-top: 90px">成员数据</div>
    <a-row type="flex" :gutter="24">
      <a-col :span="8">
        <div class="info-block ndl-padding-sm">
          <div class="info-block--title">
            现有成员数
            <a-tooltip
              title="用户中“已加入”和“未加入”成员的数量合计，如果成员人数达到上限将无法邀请新成员。"
            >
              <i class="ndl-icon-help-circle"></i>
            </a-tooltip>
          </div>
          <div class="info-block--value">
            {{ statistic.memberCount }} <small>人</small>
          </div>
          <div>
            <a-progress
              :percent="percentMember"
              :stroke-width="3"
              :show-info="false"
            />
            <span class="info-block--meta">
              成员数上限 {{ statistic.maxMember }} 人， 剩余成员数
              {{ remainMember }} 人
            </span>
          </div>
        </div>
      </a-col>
      <a-col :span="8">
        <div class="info-block ndl-padding-sm">
          <div class="info-block--title">
            现有小组数
            <a-tooltip title="组织架构中所有小组的数量统计，包括子小组。">
              <i class="ndl-icon-help-circle"></i>
            </a-tooltip>
          </div>
          <div class="info-block--value">
            {{ statistic.teamCount }} <small>个</small>
          </div>
          <div>
            <a-progress :percent="0" :stroke-width="3" :show-info="false" />
            <span class="info-block--meta">小组数暂无限制</span>
          </div>
        </div>
      </a-col>
    </a-row>
    <!-- 工作数据 -->
    <div class="ndl-text-leading" style="margin-top: 50px">工作数据</div>
    <a-row type="flex" :gutter="24">
      <a-col :span="8">
        <div
          class="info-block ndl-padding-top ndl-padding-bottom ndl-padding-left-sm ndl-padding-right-sm"
        >
          <div class="info-block--title">
            项目数量
            <a-tooltip title="空间中的项目数量统计">
              <i class="ndl-icon-help-circle"></i>
            </a-tooltip>
          </div>
          <div class="info-block--value">
            {{ statistic.projectCount }} <small>个</small>
          </div>
          <div>
            <a-progress :percent="0" :stroke-width="3" :show-info="false" />
            <!-- <span class="info-block--meta">
              空间容量上限：1 GB，剩余容量 1019.9 MB
            </span> -->
          </div>
        </div>
      </a-col>
      <a-col :span="8">
        <div
          class="info-block ndl-padding-top ndl-padding-bottom ndl-padding-left-sm ndl-padding-right-sm"
        >
          <div class="info-block--title">
            连接数量
            <a-tooltip title="空间中所有连接的数量统计">
              <i class="ndl-icon-help-circle"></i>
            </a-tooltip>
          </div>
          <div class="info-block--value">
            {{ statistic.connectorCount }} <small>个</small>
          </div>
          <div>
            <a-progress :percent="0" :stroke-width="3" :show-info="false" />
            <!-- <span class="info-block--meta">
              表格数量上限 30 个，剩余 30 个
            </span> -->
          </div>
        </div>
      </a-col>
      <a-col :span="8">
        <div
          class="info-block ndl-padding-top ndl-padding-bottom ndl-padding-left-sm ndl-padding-right-sm"
        >
          <div class="info-block--title">
            视图数量
            <a-tooltip title="空间中所有视图的数量统计">
              <i class="ndl-icon-help-circle"></i>
            </a-tooltip>
          </div>
          <div class="info-block--value">
            {{ statistic.viewCount }} <small>个</small>
          </div>
          <div>
            <a-progress :percent="0" :stroke-width="3" :show-info="false" />
            <!-- <span class="info-block--meta">
              API调用次数限额 10,000次/月，剩余用量 10,000次
            </span> -->
          </div>
        </div>
      </a-col>
    </a-row>
  </div>
</template>

<script>
import * as API from "@/api/space";

export default {
  data() {
    return {
      statistic: {
        maxMember: 50,
        memberCount: 0,
        teamCount: 0,
        projectCount: 0,
        connectorCount: 0,
        viewCount: 0
      }
    };
  },
  created() {
    this.getSpaceInfo();
  },
  computed: {
    percentMember() {
      const statistic = this.statistic;
      return (statistic.memberCount / statistic.maxMember) * 100;
    },
    remainMember() {
      const statistic = this.statistic;
      return statistic.maxMember - statistic.memberCount;
    }
  },
  methods: {
    getSpaceInfo() {
      API.getSpaceInfoCount().then(res => {
        const { data } = res;
        this.statistic = Object.assign(this.statistic, data);
      });
    }
  }
};
</script>

<style lang="less" scoped>
@import (reference) "~@/assets/app.less";

.info-block {
  border: solid 1px @ndl-border-color;
  border-radius: 4px;
  .info-block--title {
    text-align: center;
  }
  .info-block--value {
    font-size: 32px;
    line-height: 60px;
    text-align: center;
    color: @primary-color;
  }
  .info-block--value small {
    font-size: 14px;
  }
  .info-block--meta {
    font-size: 12px;
    color: @text-color-secondary;
  }
}
</style>
