<template>
  <SpaceNew
    v-if="showNewSpace"
    :space="editSpace"
    @cancel="onCancel()"
    @confirm="getSpaceList()"
  />
  <div v-else class="ndl-padding-lg ndl-width-limit">
    <header class="ndl-flex ndl-flex--middle ndl-margin-bottom-lg">
      <div class="ndl-text-heading ndl-flex-item--grow">空间列表</div>
      <a-button ghost type="primary" @click="onAdd()">
        新建空间
      </a-button>
    </header>
    <Card
      v-for="item in list"
      :key="item.spaceId"
      :space="item"
      @config="configSpace(item)"
      @delete="getSpaceList()"
    />
  </div>
</template>

<script>
import * as API from "@/api/space";
import store from "@/store";
import SpaceNew from "./components/space-new";
import Card from "./components/space-card";

export default {
  components: { SpaceNew, Card },
  data() {
    return {
      showNewSpace: false,
      editSpace: null,
      list: []
    };
  },
  created() {
    this.getSpaceList();
  },
  methods: {
    async getSpaceList() {
      const { data } = await API.listSpace();
      // 按创建时间正序排序
      data.sort((a, b) => {
        if (a.createTime > b.createTime) {
          return 1;
        }
        if (a.createTime < b.createTime) {
          return -1;
        }
        return 0;
      });
      const isSuperAdmin = store.isSuperAdmin;
      const currentMemberId = store.userInfo.memberId;
      for (const item of data) {
        item.ownerName = item.ownerName || item.ownerMail;
        const isBelongSuperAdmin = item.ownerMemberId === currentMemberId;
        item.isSuperSpace = isSuperAdmin && isBelongSuperAdmin;
      }
      this.list = data;
    },
    onAdd() {
      this.showNewSpace = true;
      this.editSpace = null;
    },
    onCancel() {
      this.showNewSpace = false;
      this.editSpace = null;
    },
    configSpace(item) {
      this.editSpace = item;
      this.showNewSpace = true;
    }
  }
};
</script>
