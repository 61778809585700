<template>
  <a-checkbox-group
    style="margin-left: 2em"
    :value="value"
    @input="$emit('input', $event)"
  >
    <a-checkbox style="margin: 1em 0; display: block;" value="connections">
      连接
    </a-checkbox>
    <a-checkbox style="margin: 1em 0; display: block;" value="datamodel">
      视图
    </a-checkbox>
    <a-checkbox style="margin: 1em 2em; display: block;" value="datamodel.api">
      视图 API
    </a-checkbox>
    <a-checkbox
      style="margin: 1em 2em; display: block;"
      value="datamodel.schedule"
    >
      视图推送
    </a-checkbox>
  </a-checkbox-group>
</template>

<script>
export default {
  props: {
    value: Array
  }
};
</script>
