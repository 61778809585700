export const columnsHistory = [
  {
    title: "资源名称",
    dataIndex: "resName",
    key: "resName"
  },
  {
    title: "用户名",
    dataIndex: "operator",
    key: "operator"
  },
  {
    title: "IP",
    dataIndex: "ip",
    key: "ip"
  },
  {
    title: "动作",
    dataIndex: "action",
    key: "action"
  },
  {
    title: "事件",
    dataIndex: "event",
    key: "event"
  },
  {
    title: "工作空间",
    dataIndex: "spaceName",
    key: "spaceName"
  },
  {
    title: "时间",
    dataIndex: "operateTime",
    key: "operateTime"
  },
  {
    title: "操作",
    key: "anyaction",
    scopedSlots: { customRender: "anyaction" }
  }
];
export const searchParams = {
  keyword: "",
  action: "",
  event: "",
  operateBegin: "",
  operateEnd: ""
};
