<template>
  <div style="text-align: center">
    <label class="icon-wrapper">
      <img :src="value || '/images/default_connector_icon.svg'" />
      <input type="file" @change="select" accept=".jpg,.png,.svg,.jpeg,.webp" />
    </label>
    <p class="description">
      <template v-if="loading">
        <a-icon type="loading" spin />
        <span class="ndl-margin-left">正在上传...</span>
      </template>
      <template v-else>连接器图标（小于 200K）</template>
    </p>
  </div>
</template>

<script>
import * as API from "@/api/common";

export default {
  props: {
    value: String
  },
  data() {
    return {
      loading: false
    };
  },
  methods: {
    async select(evt) {
      const file = evt.target.files[0];
      if (!file) {
        return;
      }
      if (file.size > 200 * 1024) {
        return this.$message.warning("请选择小于 200k 的图片");
      }
      this.loading = true;
      try {
        const { data } = await API.uploadFile(file);
        this.$emit("input", data.url);
      } finally {
        this.loading = false;
      }
    }
  }
};
</script>

<style lang="less" scoped>
@size: 80px;

.icon-wrapper {
  display: inline-block;
  width: @size;
  height: @size;
  border-radius: 4px;
  border: solid 1px #ebebeb;
  cursor: pointer;
  input {
    width: 0;
    height: 0;
    overflow: hidden;
  }
  img {
    width: 100%;
    max-height: 100%;
  }
}

.description {
  font-size: 12px;
  color: #888;
  margin-bottom: 24px;
}
</style>
