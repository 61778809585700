<template>
  <a-modal
    centered
    :mask-closable="false"
    :footer="null"
    :width="420"
    :title="model.id ? '编辑连接器' : '新建连接器'"
  >
    <a-form layout="vertical" class="ndl-antd">
      <SelectIcon v-model="model.icon" />
      <a-form-item label="名称" required>
        <a-input v-model="model.name" placeholder="请输入连接器名称" />
      </a-form-item>
      <a-form-item v-if="!model.id" label="镜像仓库" required>
        <a-input
          v-model="model.dockerRepository"
          placeholder="请输入连接器镜像仓库名称"
        />
      </a-form-item>
      <a-form-item label="镜像版本" required>
        <a-input
          v-model="model.dockerImageTag"
          placeholder="请输入连接器镜像版本"
        />
      </a-form-item>
      <div>
        <a-button
          block
          type="primary"
          :loading="saveLoading"
          :disabled="!(model.name && model.dockerImageTag)"
          @click="onSave()"
        >
          保存
        </a-button>
        <a-button
          v-if="deletable && model.id"
          block
          type="danger"
          class="ndl-margin-top"
          :loading="deleteLoading"
          @click="onDelete()"
        >
          删除
        </a-button>
      </div>
    </a-form>
  </a-modal>
</template>

<script>
import definitionList from "@/views/connections/models/definitions";
import SelectIcon from "./Icon";

export default {
  components: { SelectIcon },
  props: {
    category: String,
    deletable: Boolean,
    connector: Object,
    $resolve: Function,
    $reject: Function
  },
  data() {
    /**
     * @type {{ id?: string }}
     */
    const connector = this.connector || {};
    const defaultRepo = "ccr.ccs.tencentyun.com/dataops/source-example";
    return {
      saveLoading: false,
      deleteLoading: false,
      model: Object.assign(
        {
          icon: "",
          name: "",
          dockerRepository: connector.id ? "" : defaultRepo,
          dockerImageTag: "dev",
          documentationUrl: "",
          orderNo: null,
          disable: false,
          category: this.category
        },
        connector
      )
    };
  },
  methods: {
    async onSave() {
      try {
        this.saveLoading = true;
        await definitionList.saveDefinition(this.model);
        this.$resolve(this.model);
      } catch (err) {
        const response = err.data || {};
        this.$notification.error({
          message: "保存失败",
          description: response.msg
        });
      } finally {
        this.saveLoading = false;
      }
    },
    async onDelete() {
      try {
        this.deleteLoading = true;
        await definitionList.deleteDefinition(this.connector.id);
        this.$resolve();
      } finally {
        this.deleteLoading = false;
      }
    }
  }
};
</script>
