<template>
  <a-modal title="创建新成员" width="460px" ok-text="创建成员" @ok="onSubmit">
    <a-form :form="form" layout="vertical" class="ndl-antd">
      <!-- tricky 禁止浏览器自动填充密码 -->
      <!-- 加个 id: userName 以欺骗浏览器，避免瞎鸡儿填充 -->
      <div style="height: 0; overflow: hidden">
        <input type="text" id="userName" />
        <input type="password" />
      </div>
      <a-form-item label="登陆名称">
        <a-input
          v-decorator="['username', { rules: rules.username }]"
          placeholder="请输入登陆名称"
        />
      </a-form-item>
      <a-form-item
        label="登陆密码"
        help="请填写至少1位字母、1位数字、1位特殊字符，8位以上的密码"
      >
        <a-input
          v-decorator="['password', { rules: rules.password }]"
          type="password"
        />
      </a-form-item>
      <a-form-item label="用户昵称">
        <a-input
          v-decorator="['nickname', { rules: rules.nickname }]"
          placeholder="请输入用户昵称"
        />
      </a-form-item>
      <a-form-item label="手机号码">
        <a-input
          v-decorator="['mobile', { rules: rules.mobile }]"
          placeholder="请输入手机号码"
        />
      </a-form-item>
      <a-form-item label="邮箱">
        <a-input
          v-decorator="['mail', { rules: rules.mail }]"
          placeholder="请输入邮箱"
        />
      </a-form-item>
    </a-form>
  </a-modal>
</template>

<script>
import { createUser } from "@/api/user";

export default {
  props: {
    teamId: String,
    $resolve: Function
  },
  data() {
    return {
      form: this.$form.createForm(this),
      rules: {
        username: [{ required: true, message: "请输入登陆名称" }],
        password: [
          {
            required: true,
            trigger: "blur",
            pattern: /^(?=.*\d)(?=.*[A-Za-z])(?=.*[^\dA-Za-z]).{8,}$/
          }
        ]
      }
    };
  },
  methods: {
    async onSubmit(evt) {
      evt.preventDefault();
      await Promise.resolve(this.form.validateFields());
      try {
        const payload = this.form.getFieldsValue();
        payload.teamId = this.teamId;
        await createUser(payload);
        this.$resolve();
      } catch {
        this.$message.error("手机号或邮箱已经注册");
      }
    }
  }
};
</script>
