<template>
  <div class="table-wrapper">
    <div style="max-width: 1100px">
      <a-table
        class="ant-table-striped"
        :scroll="{ x: true }"
        size="small"
        style="margin-right: 20px"
        :loading="loading"
        :columns="columns"
        :pagination="false"
        :row-selection="rowSelection"
        :data-source="dataList"
        :rowKey="(r, i) => r.id"
      >
        <template slot="anyaction" slot-scope="text">
          <a-tooltip>
            <template slot="title">{{
              text.success == true ? "执行成功" : "执行失败"
            }}</template>
            <template>
              <a-icon
                v-if="text.success"
                style="color: rgb(25, 190, 107); margin-right: 10px"
                type="smile"
              />
              <a-icon
                v-else
                style="color: yellow; margin-right: 10px"
                type="frown"
              />
            </template>
          </a-tooltip>
          <a-popconfirm
            v-if="dataList.length"
            title="确定要删除吗?"
            @confirm="() => onDelete(1, text.id)"
          >
            <a href="javascript:;">
              <a-icon style="color: red" type="delete" />
            </a>
          </a-popconfirm>
        </template>
      </a-table>

      <div
        style="
            display: flex;
            justify-content: space-between;
            margin-bottom: 20px;
          "
      >
        <a-button
          class="pagination-margin-class"
          @click="onDelete(2)"
          type="danger"
          >删除选中</a-button
        >
        <a-pagination
          show-size-changer
          class="pagination-margin-class"
          :total="params.total"
          @change="handleTableChange"
          v-model="params.search.page"
          @showSizeChange="onShowSizeChange"
        >
        </a-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import * as API from "@/api/management";
import { columnsHistory } from "./columns-history";
export default {
  data() {
    return {
      loading: false,
      selectedRowKeys: [],
      rowSelection: {
        columnWidth: 80,
        onChange: selectedRowKeys => {
          this.selectedRowKeys = selectedRowKeys;
        }
      },
      columns: columnsHistory
    };
  },
  props: {
    dataList: Array,
    params: Object
  },
  created() {
    this.getRetrieveList();
  },
  methods: {
    //获取数据
    async getRetrieveList() {
      this.loading = true;
      await this.$emit("getRetrieveList");
      this.loading = false;
    },
    //删除数据
    async onDelete(type, id) {
      let params;
      if (type == 1) {
        params = [id];
      } else {
        if (!this.selectedRowKeys.length) {
          this.$message.error("请选择要删除的数据！");
          return;
        }
        params = this.selectedRowKeys;
      }
      await API.deleteRetriveList(params);
      this.selectedRowKeys = [];
      this.$message.success("删除成功");
      this.$emit("getRetrieveList");
    },
    //切换pageIndex
    handleTableChange(current) {
      this.params.search.page = current;
      this.$emit("getRetrieveList", { pagination: this.params, type: 2 });
    },
    //修改pagesize
    onShowSizeChange(cur, pageSize) {
      this.params.search.page = 1;
      this.params.search.size = pageSize;
      this.$emit("getRetrieveList", { pagination: this.params, type: 2 });
    }
  }
};
</script>
<style>
.table-wrapper {
  margin: 12px;
}
.ant-table-placeholder {
  border-bottom: none !important;
}
.ant-table-thead > tr > th {
  white-space: nowrap;
}
.ant-table-row td {
  white-space: nowrap;
}
.pagination-margin-class {
  margin: 10px 10px 10px 0;
}
</style>
