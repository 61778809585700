<template>
  <section class="page-wrapper ndl-flex ndl-overflow">
    <div class="sidebar-wrapper ndl-border-right ndl-flex-item--noshrink">
      <div class="ndl-padding ndl-ellipsis">
        空间管理 <span class="ndl-text-desc">@ {{ spaceName }}</span>
      </div>
      <ul class="list-wrapper">
        <template v-for="(item, index) in menus">
          <li v-if="!banMenu[item.route]" :key="index">
            <!-- 一级菜单 -->
            <component
              :is="item.children ? 'div' : 'router-link'"
              :to="{ params, name: item.route }"
              tag="div"
              class="list-item ndl-flex ndl-flex--middle"
              :class="{ silent: item.children }"
            >
              <span class="item-icon ndl-text-desc">
                <i :class="item.icon"></i>
              </span>
              <span class="item-label ndl-flex-item--grow">
                {{ item.name }}
              </span>
              <span
                v-if="item.children"
                class="item-arrow ndl-text-disabled ndl-padding-sm"
              >
                <i class="ndl-icon-chevron-down"></i>
              </span>
            </component>
            <!-- 二级菜单 -->
            <ul v-if="item.children" class="list-wrapper">
              <template v-for="(subitem, subindex) in item.children">
                <router-link
                  v-if="!banMenu[subitem.route]"
                  :key="subindex"
                  :to="{
                    params: { ...params, ...subitem.params },
                    name: subitem.route
                  }"
                  tag="li"
                  class="list-item ndl-flex ndl-flex--middle"
                >
                  <span class="item-icon"></span>
                  <span class="item-label">{{ subitem.name }}</span>
                </router-link>
              </template>
            </ul>
          </li>
        </template>
      </ul>
    </div>
    <div class="ndl-overflow ndl-flex-item--grow">
      <router-view />
    </div>
  </section>
</template>

<script>
import store from "@/store";
import PERMISSIONS from "@/constant/permissions";

export default {
  data() {
    const menus = [
      {
        icon: "ndl-icon-activity",
        name: "空间信息",
        route: "settings-overview"
      },
      store.isSuperAdmin
        ? {
            icon: "ndl-icon-grid",
            name: "空间列表",
            route: "settings-spacelist"
          }
        : null,
      {
        icon: "ndl-icon-airplay",
        name: "连接器管理",
        children: [
          {
            name: "数据源连接器",
            route: "settings-definition",
            params: { category: "source" }
          },
          {
            name: "目标库连接器",
            route: "settings-definition",
            params: { category: "destination" }
          }
        ]
      },
      {
        icon: "ndl-icon-users",
        name: "团队和成员",
        children: [
          {
            name: "团队管理",
            route: "settings-team"
          },
          {
            name: "空间管理员",
            route: "settings-manager"
          }
        ]
      },
      store.isSuperAdmin
        ? {
            icon: "ndl-icon-history-line",
            name: "操作日志",
            route: "settings-history"
          }
        : null
    ].filter(Boolean);
    return { menus };
  },
  computed: {
    spaceName() {
      return store.spaceName;
    },
    permissionMap() {
      return store.permissionMap;
    },
    params() {
      return { spaceId: store.spaceId };
    },
    banMenu() {
      const userInfo = store.userInfo;
      const permissionMap = this.permissionMap;
      return {
        // 这里不拦截 overview 菜单，因为在路由级别就已经把普通用户访问阻挡了
        "settings-workbench": !permissionMap[PERMISSIONS.MANAGE_WORKBENCH],
        "settings-member": !(
          permissionMap[PERMISSIONS.MANAGE_TEAM] &&
          permissionMap[PERMISSIONS.MANAGE_MEMBER]
        ),
        "settings-subadmin": !userInfo.isMainAdmin,
        "settings-normalmember": !permissionMap[
          PERMISSIONS.MANAGE_NORMAL_MEMBER
        ]
      };
    }
  }
};
</script>

<style lang="less" scoped>
@import (reference) "~@/assets/app.less";

.page-wrapper {
  height: 100%;

  .sidebar-wrapper {
    width: @sidebar-width;
    overflow: auto;
  }
  .list-wrapper {
    list-style: none;
    padding: 0;
  }
  .list-item {
    cursor: pointer;
    height: 40px;
    transition: color 0.2s, background-color 0.2s;
    &:hover,
    &.active,
    &.router-link-active {
      color: @primary-color;
      background: @primary-color-light;
    }
    &.silent {
      color: inherit;
      background: inherit;
      cursor: initial;
    }
  }
  .item-icon {
    width: 45px;
    padding-right: 10px;
    text-align: right;
  }
}
</style>
