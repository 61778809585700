<template>
  <div class="space-card">
    <Avatar
      size="42"
      class="space-logo"
      :color="space.spaceId | colorOf"
      :border="false"
      :src="space.spaceLogo"
      :text="space.spaceName"
    />
    <div class="space-info">
      <div class="space-name">
        <span>{{ space.spaceName }}</span>
        <a-tag v-if="space.spaceStatus == 3" color="green">永久有效</a-tag>
        <a-tooltip v-else :title="`过期时间 ${space.spaceExpireTime}`">
          <a-tag v-if="space.spaceStatus == 1" color="orange">已过期</a-tag>
          <a-tag v-else color="blue">未过期</a-tag>
        </a-tooltip>
      </div>
      <div class="space-desc ndl-color-gray">
        <span class="ndl-icon-clock"></span>
        <span> {{ space.createBy }}</span>
        <span>创建于 </span>
        <span>{{ space.createTime }}</span>
      </div>
    </div>
    <div class="space-owner">
      <a-tooltip>
        <Chip
          circle
          :src="space.ownerAvatar"
          :text="space.ownerMemberName"
          :color="space.ownerMemberId | colorOf"
          :label="space.ownerMemberName"
        />
        <table slot="title" class="tooltip-table">
          <caption>
            主管理员
          </caption>
          <tr>
            <td>昵称</td>
            <td>{{ space.ownerMemberName }}</td>
          </tr>
          <tr>
            <td>登陆账号</td>
            <td>{{ space.ownerUserName }}</td>
          </tr>
          <tr v-if="space.ownerMail">
            <td>邮箱</td>
            <td>{{ space.ownerMail }}</td>
          </tr>
          <tr v-if="space.ownerMobile">
            <td>手机号</td>
            <td>{{ space.ownerMobile }}</td>
          </tr>
        </table>
      </a-tooltip>
    </div>
    <div class="space-actions">
      <a-tooltip title="空间设置">
        <i class="ndl-icon-settings" @click="onConfig()"></i>
      </a-tooltip>
      <a-tooltip
        v-if="space.isSuperSpace"
        title="超级管理员的空间不能删除"
        placement="topRight"
      >
        <i class="ndl-icon-trash-2 ndl-color-gray"></i>
      </a-tooltip>
      <a-tooltip v-else title="删除空间">
        <i class="ndl-icon-trash-2 ndl-color-red" @click="onDelete()"></i>
      </a-tooltip>
    </div>
  </div>
</template>

<script>
import * as API from "@/api/space";
import Chip from "@/components/chip";
import Avatar, { colorOf } from "@/components/avatar";

/**
 * @typedef {{
 *  createBy: string,
 *  createTime: string,
 *  spaceId: string,
 *  spaceName: string
 * }} Space
 */
export default {
  components: { Chip, Avatar },
  props: {
    /**
     * @type {Space}
     */
    space: Object
  },
  filters: { colorOf },
  methods: {
    onConfig() {
      this.$emit("config", this.space);
    },
    onDelete() {
      this.$confirm({
        title: "删除空间",
        content: "删除后空间内所有数据都无法恢复，确定要删除吗？",
        okType: "danger",
        okText: "删除",
        cancelText: "取消",
        onOk: async () => {
          await API.deleteSpace(this.space.spaceId);
          this.$emit("delete");
        }
      });
    }
  }
};
</script>

<style lang="less" scoped>
@import (reference) "~@/assets/app.less";

.space-card {
  padding: 32px 22px;
  display: flex;
  align-items: center;
  border-bottom: solid 1px #ebebeb;
  .space-logo {
    flex-shrink: 0;
  }
  .space-info {
    margin: 0 12px;
    width: 0;
    flex: 1 1 0;
  }
  .space-name {
    font-size: 16px;
  }
  .ant-tag {
    border-radius: 2px;
    border: none;
    margin-left: 6px;
    vertical-align: 2px;
  }
  .space-desc {
    font-size: 13px;
    margin-top: 6px;
  }
  .space-owner {
    width: 0;
    flex: 1 1 0;
    min-width: 100px;
    overflow: hidden;
  }
}
.tooltip-table caption {
  color: #fff;
  text-align: center;
  font-weight: bold;
  caption-side: top;
  padding-top: 0;
}
.tooltip-table td:first-child {
  padding-right: 1em;
  color: rgba(255, 255, 255, 0.65);
}
.space-actions {
  flex-shrink: 0;
  i {
    margin-left: 1.3em;
    color: @text-color-secondary;
    cursor: pointer;
  }
  i:hover {
    color: @blue-6;
  }
}
</style>
