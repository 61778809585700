import renderModal from "@/utils/render-modal";
import Editor from "./src/Editor";

/**
 * @typedef {{ deletable: boolean, connector: object }} Options
 * @param {Options} opts
 * @returns {Promise}
 */
export default function(opts) {
  return renderModal(Editor, opts);
}
