<template>
  <div :class="['chip-wrapper', { circle }]" @click="$emit('on-click')">
    <Avatar size="20" :src="src" :color="color" :text="text" :circle="circle" />
    <span class="chip-label">
      <slot>{{ label }}</slot>
    </span>
    <i
      v-if="closable"
      class="chip-close ndl-icon-x"
      @click.stop="$emit('close')"
    ></i>
  </div>
</template>

<script>
import Avatar from "@/components/avatar";

export default {
  components: { Avatar },
  props: {
    // avatar props
    src: String,
    color: String,
    text: String,
    // chip props
    circle: {
      type: Boolean,
      default: true
    },
    label: String,
    closable: Boolean
  }
};
</script>

<style lang="less" scoped>
.chip-wrapper {
  background: #f2f4f6;
  display: inline-flex;
  align-items: center;
  border-radius: 4px;
  padding: 0 3px;
  height: 24px;
  font-size: 12px;
  cursor: pointer;
  &.circle {
    border-radius: 24px;
  }
  &:hover {
    opacity: 0.85;
  }
  .chip-label {
    padding: 0 3px;
  }
  .chip-close {
    color: rgba(0, 0, 0, 0.45);
    padding: 2px 2px 2px 0;
    &:hover {
      color: #1890ff;
    }
  }
}
</style>
