<template>
  <table class="list">
    <thead>
      <tr>
        <th>用户名称</th>
        <th>手机号码</th>
        <th>角色</th>
        <th>操作</th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="member in members" :key="member.memberId" class="list-item">
        <td>
          <Avatar
            circle
            size="32px"
            :border="false"
            :color="member.memberId | colorOf"
            :src="member.avatar"
            :text="member.memberName"
          />
          <span>{{ member.memberName }}</span>
        </td>
        <td>{{ member.mobile || "-" }}</td>
        <td>
          <Role :member="member" />
        </td>
        <!-- 如果该成员是「主管理员」，则仅主管理员自己可转让 -->
        <td v-if="member.isMainAdmin">
          <a-button
            v-if="transferable"
            size="small"
            type="danger"
            @click="$emit('change-mainadmin')"
          >
            转让主管理员
          </a-button>
        </td>
        <!-- 否则提供一个撤销按钮，所有管理员都可撤销其他子管理员 -->
        <td v-else>
          <a-button
            v-if="member.isSubAdmin"
            size="small"
            type="primary"
            @click="$emit('remove-subadmin', member)"
          >
            撤销
          </a-button>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import store from "@/store";
import Avatar, { colorOf } from "@/components/avatar";
import Role from "@/views/settings/components/member-role";

export default {
  components: { Avatar, Role },
  props: {
    members: Array
  },
  computed: {
    transferable() {
      return store.userInfo.isMainAdmin;
    }
  },
  filters: { colorOf }
};
</script>

<style lang="less" scoped>
.list {
  width: 100%;
  border-collapse: collapse;
  text-align: center;
  table-layout: fixed;
  th {
    background: #f0f0f0;
    height: 50px;
    &:first-child {
      border-top-left-radius: 6px;
    }
    &:last-child {
      border-top-right-radius: 6px;
    }
  }
  th,
  td {
    width: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  td {
    height: 64px;
  }
  td:first-child {
    padding-left: 1.6em;
    text-align: left;
    span {
      vertical-align: middle;
    }
  }
  tbody tr:hover {
    background: #f8f8f8;
  }
  .list-item {
    border-bottom: solid 1px #ebebeb;
    height: 50px;
  }
  .avatar-wrapper {
    margin-right: 8px;
  }
  .ant-btn {
    border-radius: 2px;
  }
}
</style>
