<template>
  <!-- 空间处于回收站的情况 -->
  <SpaceTrash v-if="isPreDelete" />
  <!-- 正常显示空间概览 -->
  <div v-else class="ndl-padding-lg ndl-width-limit">
    <div class="ndl-text-heading">空间信息</div>
    <!-- 基本信息 -->
    <SpaceMeta />
    <!-- 空间数据统计 -->
    <SpaceStatistic />
  </div>
</template>

<script>
import store from "@/store";
import SpaceTrash from "@/views/error/SpaceTrash";
import SpaceMeta from "./components/space-meta";
import SpaceStatistic from "./components/space-statistic";

export default {
  components: { SpaceTrash, SpaceMeta, SpaceStatistic },
  computed: {
    isPreDelete() {
      return store.spaceInfo.isPreDelete;
    }
  }
};
</script>
