<template>
  <div class="wrapper">
    <TeamList v-resizable.right class="aside" />
    <TeamMembers class="main" />
  </div>
</template>

<script>
import { resizable } from "@/utils/directives";
import TeamList from "./components/team-list";
import TeamMembers from "./components/team-members";

export default {
  components: { TeamList, TeamMembers },
  directives: { resizable }
};
</script>

<style lang="less" scoped>
@import (reference) "~@/assets/app.less";

@gutter: 2em;
.wrapper {
  height: calc(100% - 2 * @gutter);
  display: flex;
  overflow: auto;
  background: #fff;
  margin: @gutter;
  border-radius: 8px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.12);
  .aside {
    flex-shrink: 0;
    width: 280px;
    min-width: 200px;
    max-width: 400px;
    border-right: solid 1px #ebebeb;
    position: relative;
  }
  .main {
    flex: 1 1 0;
  }
}
</style>
