<template>
  <div>
    <div class="meta-row">
      <span class="label ndl-text-desc">名称</span>
      <span>{{ spaceInfo.spaceName }}</span>
      <a v-if="isMainAdmin" class="ndl-margin-left-lg" @click="editSpaceName()">
        <i class="ndl-icon-edit"></i> 修改名称
      </a>
    </div>
    <div class="meta-row ndl-flex ndl-flex--middle">
      <span class="label ndl-text-desc">Logo</span>
      <Avatar
        :color="spaceInfo.spaceId | colorOf"
        :src="spaceInfo.spaceLogo"
        :text="spaceInfo.spaceName"
      />
      <a v-if="isMainAdmin" class="ndl-margin-left-lg" @click="editSpaceLogo()">
        <i class="ndl-icon-edit"></i> 修改
      </a>
    </div>
    <div class="meta-row">
      <span class="label ndl-text-desc">创建人</span>
      <span>{{ spaceInfo.createBy }}</span>
    </div>
    <div class="meta-row">
      <span class="label ndl-text-desc">主管理员</span>
      <span>{{ spaceInfo.ownerMemberName }}</span>
    </div>
    <div class="meta-row">
      <span class="label ndl-text-desc">创建时间</span>
      <span>{{ spaceInfo.createTime }}</span>
    </div>
  </div>
</template>

<script>
import store from "@/store";
import * as API from "@/api/space";
import Avatar, { colorOf } from "@/components/avatar";
import editName from "@/components/edit-name";
import selectImage from "@/components/cropper";

export default {
  components: { Avatar },
  computed: {
    // 空间主管理员
    isMainAdmin() {
      return store.userInfo.isMainAdmin;
    },
    spaceInfo() {
      return store.spaceInfo;
    }
  },
  filters: { colorOf },
  methods: {
    async editSpaceName() {
      const spaceInfo = store.spaceInfo;
      const spaceName = await editName({
        name: spaceInfo.spaceName,
        title: "修改空间名称"
      });
      if (spaceName) {
        await API.simpleUpdateSpace({ spaceName, spaceId: spaceInfo.spaceId });
        store.updateUserInfo();
      }
    },
    async editSpaceLogo() {
      const spaceInfo = store.spaceInfo;
      const newImage = await selectImage({
        title: "选择图标",
        gallery: "space",
        src: spaceInfo.spaceLogo,
        color: colorOf(spaceInfo.spaceId),
        text: spaceInfo.spaceName
      });
      spaceInfo.spaceLogo = newImage;
      const data = { spaceLogo: newImage, spaceId: spaceInfo.spaceId };
      await API.simpleUpdateSpace(data);
      store.updateUserInfo();
    }
  }
};
</script>

<style lang="less" scoped>
.meta-row {
  margin: 28px 0;
}
.meta-row .label {
  width: 110px;
  display: inline-block;
}
</style>
