<template>
  <div class="toolbar">
    <AddMember />
    <a-button
      size="small"
      type="danger"
      :disabled="!checkedMembers.length"
      @click="onRemoveMembers()"
    >
      移除成员{{ checkedMembers.length | padding }}
    </a-button>
    <a-button
      v-if="deletable"
      size="small"
      type="danger"
      @click="onRemoveTeam()"
    >
      删除团队
    </a-button>
    <!-- 占位符 -->
    <span style="flex: 1 1 0"></span>
    <label class="check-all">
      <span>选择全部</span>
      <a-checkbox v-bind="checkAll" @input="onCheckAll" />
    </label>
  </div>
</template>

<script>
import org from "@/views/settings/models/organization";
import AddMember from "./ToolbarAddMember";

export default {
  components: { AddMember },
  computed: {
    members() {
      return org.focusedTeam.members || [];
    },
    checkedMembers() {
      return this.members.filter(item => item.checked);
    },
    deletable() {
      return !org.focusedTeam.isRoot;
    },
    checkAll() {
      const members = this.members;
      const checked = !!members.length && members.every(item => item.checked);
      const indeterminate = !checked && members.some(item => item.checked);
      return { checked, indeterminate };
    }
  },
  filters: {
    padding(len) {
      return len ? `(${len})` : "";
    }
  },
  methods: {
    onRemoveMembers() {
      const members = this.checkedMembers;
      const len = members.length;
      if (!len) {
        return;
      }
      this.$confirm({
        title: org.focusedTeam.isRoot
          ? "是否将所选成员移出空间？"
          : "是否将所选成员移出团队？",
        content:
          len > 1
            ? `正在移除「${members[0].memberName}」等 ${len} 位成员`
            : `正在移除成员「${members[0].memberName}」`,
        width: 380,
        okType: "danger",
        okText: org.focusedTeam.isRoot ? "确认移出空间" : "确认移出团队",
        onOk: () => {
          const teamId = org.focusedTeam.teamId;
          const memberId = members.map(item => item.memberId);
          return org.removeMembers({ teamId, memberId });
        }
      });
    },
    onRemoveTeam() {
      this.$confirm({
        title: "是否确定要删除团队？",
        content: `正在删除团队「${org.focusedTeam.teamName}」`,
        width: 380,
        okType: "danger",
        okText: "确认删除团队",
        onOk: () => org.removeTeam(org.focusedTeam)
      });
    },
    onCheckAll(checked) {
      for (const item of this.members) {
        item.checked = checked;
      }
    }
  }
};
</script>

<style lang="less" scoped>
.toolbar {
  padding: 20px;
  display: flex;
  align-items: center;
  .ant-btn {
    margin-right: 14px;
    border-radius: 2px;
  }
  .check-all {
    font-size: 13px;
    cursor: pointer;
    user-select: none;
    color: rgba(0, 0, 0, 0.45);
  }
  .ant-checkbox-wrapper {
    margin-left: 8px;
  }
}
</style>
