<template>
  <div>
    <history-header @getRetrieveList="getRetrieveList"></history-header>
    <history-body
      :dataList="dataList"
      :params="params"
      @getRetrieveList="getRetrieveList"
    ></history-body>
  </div>
</template>

<script>
import * as API from "@/api/management";
import historyHeader from "./components/history/historyHeader.vue";
import historyBody from "./components/history/historyBody.vue";
export default {
  data() {
    return {
      dataList: [],
      params: {
        search: {
          keyword: "",
          action: "",
          event: "",
          operateBegin: "",
          operateEnd: "",
          page: 1,
          size: 10
        },
        total: 0
      }
    };
  },
  created() {
    this.getRetrieveList();
  },
  components: {
    historyHeader,
    historyBody
  },
  computed: {},
  methods: {
    async getRetrieveList(par) {
      if (par && par.type == 2) {
        this.params.search = Object.assign(
          this.params.search,
          par.pagination.search
        );
      }
      if (par && par.type == 1) {
        this.params.search = Object.assign(
          this.params.search,
          par.searchParams
        );
      }
      const { data } = await API.retrieveList(this.params.search);
      this.params.total = data.total;
      this.dataList = data.list;
    }
  }
};
</script>
<style></style>
