<template>
  <div class="table-wrapper">
    <div>
      <a-input-search
        :allowClear="true"
        v-model="searchParams.keyword"
        placeholder="输入资源名称"
        class="search-class"
        @search="getRetrieveList"
      />
      <span>动作：</span>
      <a-select
        :dropdown-match-select-width="false"
        v-model="searchParams.action"
        class="select-class"
      >
        <a-select-option value="">
          全部
        </a-select-option>
        <a-select-option
          v-for="(item, index) in actionList"
          :key="index"
          :value="item"
          >{{ item }}</a-select-option
        >
      </a-select>
      <span>事件：</span>
      <a-select
        :dropdown-match-select-width="false"
        v-model="searchParams.event"
        class="select-class"
      >
        <a-select-option value="">
          全部
        </a-select-option>
        <a-select-option
          v-for="(item, index) in eventList"
          :key="index"
          :value="item"
          >{{ item }}</a-select-option
        >
      </a-select>

      <a-date-picker
        show-time
        @change="changeTime"
        v-model="searchParams.operateBegin"
        placeholder="开始时间"
        class="margin-class"
      />

      <a-date-picker
        show-time
        @change="changeTime2"
        v-model="searchParams.operateEnd"
        :disabled-date="disabledDate"
        placeholder="结束时间"
        class="margin-class"
      />

      <a-button class="margin-class" @click="clearSearchValue">重置</a-button>
      <a-button class="margin-class" @click="getRetrieveList" type="primary"
        >搜索</a-button
      >
    </div>
  </div>
</template>

<script>
import * as API from "@/api/management";
import { searchParams } from "./columns-history";
import dayjs from "dayjs";
export default {
  data() {
    return {
      searchParams: searchParams,
      selectedRowKeys: [],
      actionList: [],
      eventList: []
    };
  },
  created() {
    this.getActionList();
    this.getEventList();
  },
  methods: {
    //获取动作下拉select数组
    async getActionList() {
      const { data } = await API.getActionList();
      this.actionList = data;
    },
    //获取事件下拉select数组
    async getEventList() {
      const { data } = await API.getEventList();
      this.eventList = data;
    },
    //重置搜索条件
    clearSearchValue() {
      for (const key in this.searchParams) {
        this.searchParams[key] = "";
      }
      this.getRetrieveList();
    },
    //结束时间禁选 开始<结束
    disabledDate(current) {
      if (this.searchParams.operateBegin) {
        return current && current < dayjs(this.searchParams.operateBegin);
      }
      return false;
    },
    getRetrieveList() {
      this.$emit("getRetrieveList", {
        searchParams: this.searchParams,
        type: 1
      });
    },
    changeTime(date) {
      this.searchParams.operateBegin = dayjs(date).format(
        "YYYY-MM-DD HH:mm:ss"
      );
    },
    changeTime2(date) {
      this.searchParams.operateEnd = dayjs(date).format("YYYY-MM-DD HH:mm:ss");
    }
  }
};
</script>
<style lang="less" scoped>
@mar: 10px 5px 10px 0;
.select-class {
  width: 70px;
  margin: @mar;
}
.margin-class {
  margin: @mar;
}
.search-class {
  width: 130px;
  margin: @mar;
}
</style>
