import Axios from "axios";
import { interceptor } from "./index";
import resolveURL from "@/utils/baseurl";

const axios = Axios.create({ baseURL: resolveURL("/api") });
// const axios = Axios.create({
//   baseURL: "http://datamesh.eiplatform.cn/api/management",
// });

interceptor.auth(axios);

export function retrieveList(params) {
  return axios.get("/audit/getAuditList", { params });
}

export function getActionList(data) {
  return axios.get("/audit/action", data);
}

export function getEventList(data) {
  return axios.get("/audit/event", data);
}

export function deleteRetriveList(data) {
  return axios.delete("/audit/delete", { data });
}

export default axios;
