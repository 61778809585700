<template>
  <Pane title="团队" footer-styles="padding: 8px">
    <ListItem
      v-for="item in org.teams"
      :key="item.teamId"
      :active="item === org.focusedTeam"
      :team="item"
      @select="onSelect(item)"
      @edit="onEdit(item)"
      @remove="onRemove(item)"
    />
    <a-button slot="footer" type="primary" ghost block @click="onCreate()">
      创建团队
    </a-button>
  </Pane>
</template>

<script>
import Pane from "@/views/settings/components/team-pane";
import org from "@/views/settings/models/organization";
import editName from "@/components/edit-name";
import ListItem from "./ListItem";

export default {
  components: { Pane, ListItem },
  data() {
    return { org };
  },
  methods: {
    onSelect(item) {
      org.focusOnTeam(item);
    },
    async onCreate() {
      const name = await editName({
        title: "创建团队",
        okText: "创建团队"
      });
      if (name) {
        const parentId = org.orgId;
        org.saveTeam({ name, parentId });
      }
    },
    async onEdit(item) {
      const teamName = await editName({
        title: "修改团队名称",
        name: item.teamName
      });
      if (teamName && item.teamName !== teamName) {
        item.teamName = teamName;
        org.saveTeam(item);
      }
    },
    onRemove(item) {
      this.$confirm({
        title: "是否确定要删除团队？",
        content: `正在删除团队「${item.teamName}」`,
        width: 380,
        okType: "danger",
        okText: "确认删除团队",
        onOk: () => org.removeTeam(item)
      });
    }
  }
};
</script>
