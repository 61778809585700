<template>
  <div class="ndl-padding-lg ndl-width-limit">
    <header class="ndl-flex ndl-flex--middle">
      <div class="ndl-text-heading ndl-flex-item--grow">{{ title }}</div>
      <a-button
        v-if="isSuperAdmin"
        ghost
        type="primary"
        class="ndl-margin-right"
        @click="onAdd()"
      >
        新建连接器
      </a-button>
    </header>
    <Draggable :list="list" class="list" @end="onSortEnd()">
      <div
        v-for="item in list"
        :key="item.id"
        :class="['list-item']"
        @click="onSelect(item)"
      >
        <img :src="item.icon || '/images/default_connector_icon.svg'" />
        <span class="title">
          {{ item.name }}
        </span>
        <span class="desc">{{ item.dockerImageTag }}</span>
      </div>
    </Draggable>
  </div>
</template>

<script>
import * as API from "@/api/connections";
import store from "@/store";
import Draggable from "vuedraggable";
import { Types, localeTypes } from "@/views/connections/models/helper";
import definitionList from "@/views/connections/models/definitions";
import editDefinition from "./components/definition-editor";

export default {
  components: { Draggable },
  data() {
    return { list: [] };
  },
  computed: {
    // 超级超级超级管理员才能创建 / 删除 / 修改服务地址
    isSuperAdmin() {
      return store.isSuperAdmin;
    },
    category() {
      return this.$route.params.category;
    },
    title() {
      return `${localeTypes(this.category)}连接器管理(${this.list.length})`;
    }
  },
  created() {
    this.resetList();
  },
  watch: {
    $route() {
      this.resetList();
    }
  },
  methods: {
    async resetList() {
      await definitionList.init();
      switch (this.category) {
        case Types.SOURCE:
          this.list = definitionList.sources;
          break;
        case Types.DESTINATION:
          this.list = definitionList.destinations;
          break;
        default:
      }
    },
    onSortEnd() {
      const list = this.list || [];
      list.forEach((item, index) => {
        item.orderNo = index;
      });
      definitionList.sort();
      const data = list.map((item, orderNo) => ({ orderNo, id: item.id }));
      API.sortDefinitions(data, { category: this.category });
    },
    onAdd() {
      editDefinition({
        connector: null,
        deletable: false,
        category: this.category
      }).then(this.resetList);
    },
    onSelect(connector) {
      editDefinition({
        connector,
        deletable: this.isSuperAdmin,
        category: this.category
      }).then(this.resetList);
    }
  }
};
</script>

<style lang="less" scoped>
@import (reference) "~@/views/connections/assets/vars.less";

@width: 180px;
@height: 120px;
@gutter: 20px;

.list {
  display: flex;
  flex-wrap: wrap;
  max-width: (@width + @gutter * 2) * 4;
  margin-top: 40px;
}

.list-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: @height;
  width: @width;
  margin: @gutter;
  padding: 12px;
  border: solid 1px #ebebeb;
  border-radius: 12px;
  background: #fff;
  cursor: pointer;

  // &.disabled {
  //   filter: grayscale(100%);
  //   background: #f5f5f5;
  // }
  img {
    max-width: 90px;
    height: 40px;
    flex-shrink: 0;
  }
  .title {
    margin-top: 10px;
    color: @local-title-color;
  }
  .desc {
    font-size: 13px;
    color: @text-color-secondary;
  }
}
</style>
