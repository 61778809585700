<template>
  <div class="pane">
    <div class="header">
      <slot name="header">{{ title }}</slot>
    </div>
    <div class="main">
      <slot></slot>
    </div>
    <div class="footer" :style="footerStyles">
      <slot name="footer"></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: String,
    footerStyles: [Object, String]
  }
};
</script>

<style lang="less" scoped>
.pane {
  display: flex;
  flex-direction: column;
  .header {
    line-height: 42px;
    background-color: #f0f0f0;
    text-align: center;
    font-weight: bold;
  }
  .header,
  .footer {
    flex-shrink: 0;
  }
  .main {
    flex: 1 1 0;
    overflow: auto;
  }
}
</style>
