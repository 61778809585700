<template>
  <Pane title="成员">
    <Toolbar />
    <a-empty v-if="!members.length" class="ndl-margin-top-lg" />
    <div class="member-list">
      <Member v-for="item in members" :key="item.memberId" :member="item" />
    </div>
  </Pane>
</template>

<script>
import org from "@/views/settings/models/organization";
import Pane from "@/views/settings/components/team-pane";
import Toolbar from "./Toolbar";
import Member from "./Member";

export default {
  components: { Pane, Toolbar, Member },
  data() {
    return { org };
  },
  computed: {
    members() {
      return org.focusedTeam.members;
    }
  },
  methods: {}
};
</script>

<style lang="less" scoped>
.pane /deep/ .main {
  display: flex;
  flex-direction: column;
}
.toolbar {
  flex-shrink: 0;
}
.member-list {
  flex: 1 1 0;
  overflow: auto;
}
</style>
