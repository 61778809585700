<template>
  <a-dropdown>
    <a-button size="small" type="primary">
      <span>添加团队成员&nbsp;</span>
      <i class="ndl-icon-chevron-down"></i>
    </a-button>
    <a-menu slot="overlay" @click="onAddMember">
      <a-menu-item key="exist-user">添加空间内的其他成员...</a-menu-item>
      <a-menu-divider />
      <a-menu-item key="create-user">创建新成员</a-menu-item>
      <a-menu-item key="import-user">批量导入</a-menu-item>
    </a-menu>
  </a-dropdown>
</template>

<script>
import org from "@/views/settings/models/organization";
import pickMembers from "@/views/settings/components/unit-picker";
import createMember from "@/views/settings/components/member-create";
import uploadMember from "@/views/settings/components/member-upload";

export default {
  methods: {
    async onAddMember(evt) {
      const teamId = org.focusedTeam.teamId;
      switch (evt.key) {
        case "exist-user":
          return this.pickUnits();
        case "create-user":
          await createMember({ teamId });
          return org.update();
        case "import-user":
          await uploadMember({ teamId });
          return org.update();
      }
    },
    async pickUnits() {
      const units = await pickMembers();
      const unitList = units.map(item => {
        return { id: item.unitId, type: item.unitType };
      });
      const teamId = org.focusedTeam.teamId;
      return org.addMembers({ teamId, unitList });
    }
  }
};
</script>
