<template>
  <DefinitionList
    :category="category"
    :item-class="getItemClass"
    @select="onSelect"
  >
    <Checkbox slot="extra" checked />
  </DefinitionList>
</template>

<script>
import DefinitionList from "@/views/connections/components/definition-list";
import Checkbox from "@/components/checkbox";

export default {
  props: {
    value: Array,
    category: String
  },
  components: { Checkbox, DefinitionList },
  methods: {
    isChecked(item) {
      return [].concat(this.value).includes(item.id);
    },
    getItemClass(item) {
      return this.isChecked(item) ? "checked" : "";
    },
    onSelect(item) {
      const values = [].concat(this.value);
      const index = values.indexOf(item.id);
      if (index >= 0) {
        values.splice(index, 1);
      } else {
        values.push(item.id);
      }
      this.$emit("input", values);
    }
  }
};
</script>

<style lang="less" scoped>
@import (reference) "~@/assets/app.less";

.checkbox {
  position: absolute;
  top: 10px;
  right: 12px;
  visibility: hidden;
}
.list-wrapper /deep/ .list-item.checked {
  border-color: @blue-6;
  background: @blue-1;
  .checkbox {
    visibility: visible;
  }
}
</style>
